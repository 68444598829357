<template>
  <div>
    <VTitle :title="$t('app.trainings', 2)" class="section-title" />

    <VList
      hide-header
      :headers="headers"
      :rows="data"
      :is-loading="isLoading"
      :expanded-indexes="expandedIndexes"
    >
      <!-- eslint-disable-next-line -->
      <template #item.image="{ item }">
        <VImage
          :color="item?.training?.colour"
          :name="getText(item?.training?.texts, 'name')"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.training="{ item }">
        {{ getText(item?.training?.texts, "name") }}

        <div class="version mt-0.5">
          {{ $t("app.versions") }} {{ item?.training?.version }}
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.started_on="{ item }">
        {{ formatDate({ date: item?.started_on }) }}
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.learner_completed_on="{ item }">
        {{ formatDate({ date: item?.learner_completed_on }) }}
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.collection="{ item }">
        <div v-if="item?.collection_registration?.collection">
          {{
            getText(item?.collection_registration?.collection?.texts, "name")
          }}

          <div class="version mt-0.5">
            {{ $t("app.versions") }}
            {{ item?.collection_registration?.collection?.version }}
          </div>
        </div>

        <div v-else>
          -
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.result="{ item }">
        <div class="flex justify-center">
          <VChip
            :text="$t(`app.${item.result}`)"
            :class="getStatusColor(item.result)"
          />
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.status="{ item }">
        <div class="flex justify-center">
          <VChip
            :text="$t(`app.${item.status}`)"
            :class="getStatusColor(item.status)"
          />
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #header.__ACTIONS="{ index }">
        <div class="flex justify-center">
          <VAction
            :icon="isExpandedAll ? 'collapse-all' : 'expand-all'"
            @click="onClickToggleExpandAll(data.length)"
          />
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.__ACTIONS="{ item, index }">
        <div class="flex justify-center">
          <VAction
            :icon="expandedIndexes.includes(index) ? 'collapse' : 'expand'"
            @click="onClickToggleExpand(index)"
          />
        </div>
      </template>

      <template #expanded-item="{ item }">
        <div class="grid grid-cols-2 gap-4 p-3">
          <VLine
            :label="$t('app.registered_on')"
            :value="formatDate({ date: item.registered_on })"
          />

          <VLine
            :label="$t('app.cancelled_on')"
            :value="formatDate({ date: item.cancelled_on })"
          />

          <VLine
            :label="$t('app.certificate_valid_until')"
            :value="formatDate({ date: item.certificate_valid_until })"
          />

          <VLine
            :label="$t('app.completion_time')"
            :value="item.completion_time"
          />

          <VLine
            :label="$t('app.learner_comments')"
            :value="item.learner_comments"
          />

          <div class="col-span-2 border-b border-gray-200 w-full" />

          <div class="col-span-2">
            <VLine :label="$t('app.registration_approver')" class="mb-2" />
            <VAvatar :item="item.registration_approver" />
          </div>

          <VLine
            :label="$t('app.registered_via')"
            :value="item.registered_via"
          />

          <VLine
            :label="$t('app.registration_requested_on')"
            :value="formatDate({ date: item.registration_requested_on })"
          />

          <div class="col-span-2 border-b border-gray-200 w-full" />

          <div class="col-span-2">
            <VLine :label="$t('app.completion_approver')" class="mb-2" />
            <VAvatar :item="item.completion_approver" />
          </div>

          <VLine
            :label="$t('app.completion_approval_requested_on')"
            :value="formatDate({ date: item.completion_approval_requested_on })"
          />

          <VLine
            :label="$t('app.completion_approved_on')"
            :value="formatDate({ date: item.completion_approved_on })"
          />

          <VLine
            :label="$t('app.completion_deadline')"
            :value="formatDate({ date: item.completion_deadline })"
          />

          <VLine
            :label="$t('app.completion_approver_comments')"
            :value="item.completion_approver_comments"
          />
        </div>
      </template>
    </VList>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useToggleExpand from "@/composables/useToggleExpand";
import useColor from "@/composables/useColor";
import useTexts from "@/composables/useTexts";
import useDisplay from "@/composables/useDisplay";
import useRequest from "@/composables/useRequest";
// Components
import VTitle from "@/components/VTitle";
import VList from "@/components/tables/VList";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";
import VAction from "@/components/tables/VAction";
import VLine from "@/components/VLine";
import VAvatar from "@/components/VAvatar";

export default {
  components: {
    VTitle,
    VAvatar,
    VList,
    VImage,
    VChip,
    VAction,
    VLine
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { request } = useRequest();
    const { getText } = useTexts();
    const { getStatusColor } = useColor();
    const { formatDate } = useDisplay();
    const {
      expandedIndexes,
      isExpandedAll,
      onClickToggleExpand,
      onClickToggleExpandAll
    } = useToggleExpand();

    // Constants
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.trainings"),
        value: "training"
      },
      {
        text: t("app.collections"),
        value: "collection"
      },
      {
        text: t("app.started_on"),
        value: "started_on"
      },
      {
        text: t("app.learner_completed_on"),
        value: "learner_completed_on"
      },
      {
        text: t("app.result"),
        value: "result",
        headerClass: "justify-center"
      },
      {
        text: t("app.status"),
        value: "status"
      },
      {
        text: t("app.actions"),
        value: "__ACTIONS"
      }
    ];

    // Data
    const data = ref([]);
    const isLoading = ref(false);

    // Computed
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.trainings", 2)} - ${t("app.learners", 2)} - ${t("app.teaching")}`;

      const { firstname, lastname } = props.resource?.firstname ?? {};
      if (firstname && lastname) {
        return `${firstname} ${lastname} - ${title}`;
      }

      return title;
    });

    // Methods
    const getData = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "teaching.learners.trainings",
        pathParams: {
          id: props.id
        },
        showToaster: false
      });

      data.value = response?.payload?.data ?? [];
      isLoading.value = false;
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      documentTitle,
      headers,
      data,
      isLoading,
      // useToggleExpand
      expandedIndexes,
      isExpandedAll,
      onClickToggleExpand,
      onClickToggleExpandAll,
      // useTexts
      getText,
      // useColor
      getStatusColor,
      // useDisplay
      formatDate
    };
  }
};
</script>
